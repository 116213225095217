
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AdminStats, OrderStatus } from '@/interfaces';
import StatBox from '@/components/StatBox.vue';
import CountriesFilter from '@/components/filters/Countries.vue';

const appStore = namespace('app');
const statsStore = namespace('stats');

@Component({ components: { CountriesFilter, StatBox } })
export default class HomePage extends Vue {
  stats: AdminStats = {
    locations: 0,
    concepts: 0,
    conceptsOpen: 0,
    conceptsClose: 0,
    conceptsWorking: 0,
    conceptsProfiles: 0,
    ordersToday: 0,
    ordersRejected: 0,
    orderSpecialOffers: 0,
    ordersFreeDelivery: 0,
    ordersYesterday: 0,
    ordersYesterdayRejected: 0,
    orderYesterdaySpecialOffers: 0,
    ordersYesterdayFreeDelivery: 0,
  };

  @appStore.Getter
  readonly isRebel!: boolean;

  async mounted(): Promise<void> {
    if (this.isRebel) {
      this.stats = await this.getAdminStats();
    } else {
      this.$router.push({ name: 'orders', query: { status: OrderStatus.new } }).catch(() => null);
    }
  }

  @statsStore.Action
  getAdminStats!: (countries?: string[]) => Promise<AdminStats>;

  async onCountryChange(countries: string[]): Promise<void> {
    this.stats = await this.getAdminStats(countries);
  }

  redirectToClosedLocations() {
    this.$router.push({ path: 'locations', query: { closed: 'true' } });
  }
}

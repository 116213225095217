
  import { Prop, Component, Vue } from 'vue-property-decorator'
  import { Route } from 'vue-router';

  @Component
  export default class StatBox extends Vue {
    @Prop() readonly name!: string;
    @Prop() readonly value!: string | number;
    @Prop() readonly link?: string | Route;
  }
